<template>
    <div class="show-not-found background-not-supported">
        <div class="container-flex">
             <img :src="logoCaredokter" />
             <img :src="errorLaptop" />
                <label class="font-forbidden">Account can not be linked</label>
                <label class="font-access"
                    >Akun perujuk telah terhubung dengan akun lain</label
                >
                <button class="btn btn-primary-caredokter" @click="adminContact">
                    Kontak Admin
            </button>
        </div>
    </div>
    
    
    
    
    
    
    
<!--     
    <div>
        <h1>Error Linked</h1>
        <button @click="backHome">
            Back To Home
        </button>
    </div> -->
</template>
<script>

import laptopError from "@/assets/laptop-error.png";
import logoCaredokter from "@/assets/caredokter_horizontal.png";

export default {
    data () {
        return {
            logoCaredokter: `${logoCaredokter}`,
            errorLaptop: `${laptopError}`
        }
    },
    methods: {
        adminContact () {
          window.keycloak.loadUserInfo()
            .then(_resp => {
              this.getN8N(_resp.preferred_username,
              'Anda tidak memiliki akses untuk menghubungkan perujuk',
               _resp.email,
                 _resp.sub,
               _resp.tenant_id)
              window.keycloak.logout()
            })
        },
         getN8N (username, message, email,  sub, tenant) {
          const origin = window.location.origin
          const url = `https://n8n.mandayamedical.group/webhook/KontakAdmin?un=${username}&msg=${message}&em=${email}&sub=${sub}&tenant=${tenant}&origin=${origin}`
          this.popupCenter({url, title: '_blank', w: 600, h: 200});   
        },
        popupCenter ({url, title, w, h}) {
          // Fixes dual-screen position                             Most browsers      Firefox
          const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
          const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

          const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
          const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

          const systemZoom = width / window.screen.availWidth;
          const left = (width - w) / 2 / systemZoom + dualScreenLeft
          const top = (height - h) / 2 / systemZoom + dualScreenTop
          const newWindow = window.open(url, title, 
            `
            scrollbars=yes,
            width=${w / systemZoom}, 
            height=${h / systemZoom}, 
            top=${top}, 
            left=${left}
            `
          )

          if (window.focus) newWindow.focus();
         }
    }
}
</script>